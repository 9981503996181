import { RoutesOverviewFilterResponse } from "@lib/utils/types/filters/RoutesOverviewFiltersResponse";

import { RoutesOverviewActiveFilters } from "../../hooks/useRoutesOverviewActiveFilters";
import { DifficultiesFilter } from "./filters/DifficultiesFilter";
import { LengthFilter } from "./filters/LengthFilter";
import { PathsFilter } from "./filters/PathsFilter";

type SelectFiltersProps = {
  filters: RoutesOverviewFilterResponse;
  resultCount: number;
  activeFilters: RoutesOverviewActiveFilters;
};

export function SelectFilters({
  filters,
  resultCount,
  activeFilters,
}: SelectFiltersProps) {
  return (
    <div className="flex gap-xl overflow-auto px-md py-lg lg:gap-3xl lg:px-4xl">
      <PathsFilter
        availableRegions={filters.regions}
        resultCount={resultCount}
        selectedPaths={activeFilters.values.pathIds}
        onToggle={value => activeFilters.toggle("pathId", value)}
        onToggleAll={values => activeFilters.toggleAll("pathId", values)}
        onClear={() => activeFilters.clear("pathId")}
      />
      <LengthFilter
        resultCount={resultCount}
        rangeBounds={filters.length}
        value={activeFilters.values.length}
        onValueChanged={({ min, max }) => {
          if (min === filters.length.min && max === filters.length.max) {
            activeFilters.replace({
              lengthMin: [],
              lengthMax: [],
            });
          } else {
            activeFilters.replace({
              lengthMin: min.toString(),
              lengthMax: max.toString(),
            });
          }
        }}
      />
      {activeFilters.values.type === "hiking" && (
        <DifficultiesFilter
          availableDifficulties={filters.difficulties}
          resultCount={resultCount}
          selectedDifficulties={activeFilters.values.difficulties}
          onToggle={value => activeFilters.toggle("difficulty", value)}
          onClear={() => activeFilters.clear("difficulty")}
        />
      )}
    </div>
  );
}

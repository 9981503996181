import { ILink } from "@components/blocks/linkBlock/LinkBlock";
import { generateInternalLinkHref } from "@lib/utils";

import { assertUnreachable } from "./assertUnreachable";

export function composeHref(link: ILink) {
  switch (link.linkType) {
    case "Email":
      return `mailto:${link.email}`;
    case "Phone Number":
      return `tel:${link.phoneNumber.replace(/\s/g, "")}`;
    case "Internal link":
      return generateInternalLinkHref(
        link.internalLink.pageType,
        link.internalLink.slug,
        link.internalLink.parentSlug,
        link.internalLink.grandparentSlug,
        link.internalLink.language
      );
    case "External link":
      return link.link;
    default:
      assertUnreachable(link);
  }
}

import { useEffect, useRef } from 'react';

export const useUpdateEffect = (fn: () => void, inputs: unknown[]) => {
    const didMountRef = useRef(false);

    useEffect(() => {
        if (didMountRef.current) {
            return fn();
        }
        didMountRef.current = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, inputs);
};

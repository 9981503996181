import { components } from "@components/sections/richtextSection/RichtextSection";
import { TabContent, TabHeader } from "@components/ui/tab";
import { SanityBlock } from "@lib/utils/types/SanityBlock";
import { SanityKeyed } from "@lib/utils/types/SanityKeyed";
import { PortableText } from "@portabletext/react";
import { forwardRef, ReactNode } from "react";

export interface ITabBlock {
  _type: "tab";
  _key: string;
  title: string;
  richText: Array<SanityKeyed<SanityBlock>>;
}

interface ITabBlockHeader extends ITabBlock {
  isActive: boolean;
  children: ReactNode;
}

export const TabBlockHeader = forwardRef<HTMLButtonElement, ITabBlockHeader>(
  function TabBlockHeader({ _key, title, isActive = false, children }, ref) {
    return (
      <TabHeader
        value={_key}
        className={`flex-1 text-nowrap border-b-grey px-10 py-4 text-center text-base font-normal text-primary-dark transition-colors duration-200 hover:border-b-2 hover:border-b-border-black lg:px-10 [&[data-state='active']]:font-bold ${isActive ? "[&[data-state='active']]:border-b-border" : "[&[data-state='active']]:border-b-grey"}`}
        ref={ref}
      >
        {children || title}
      </TabHeader>
    );
  }
);

export function TabBlockContent({ _key, richText }: ITabBlock) {
  return (
    <TabContent value={_key} className="group" forceMount>
      <div className="grid grid-rows-[0fr] transition-all delay-300 duration-0 group-data-[state='active']:grid-rows-[1fr]">
        <div className="overflow-hidden opacity-0 transition-all duration-300 group-data-[state='inactive']:invisible group-data-[state='active']:opacity-100 group-data-[state='active']:delay-300">
          <PortableText value={richText} components={components} />
        </div>
      </div>
    </TabContent>
  );
}

import { ContentCard } from "@components/blocks/contentCard/ContentCard";
import { ImageBlockType } from "@components/blocks/imageBlock/ImageBlock";
import { RouteType } from "@repo/shared-types";
import { useTranslations } from "use-intl";

interface PathCardProps {
  slug: string;
  name: string;
  language: string;
  region: string;
  length: number | null;
  estimatedTime: string | null;
  image: ImageBlockType | null;
  type?: RouteType;
}

export async function PathCard({
  slug,
  name,
  region,
  length,
  estimatedTime,
  image,
  type,
  language,
}: PathCardProps) {
  const t = useTranslations("Filters.Paths");
  const additionalInfo =
    length && estimatedTime
      ? `${length} ${t("Km")} · ${estimatedTime}`
      : `${length} ${t("Km")}`;

  return (
    <ContentCard
      label={<span className="uppercase lg:hidden">{t("Route")}</span>}
      title={name}
      cardSubtitleStyle="text-lg md:text-2xl"
      content={region}
      additionalInfo={<span className="text-sm">{additionalInfo}</span> ?? ""}
      imageBlock={image}
      imageRatio={1}
      type={type && type}
      link={{
        label: t("ReadMore"),
        linkType: "Internal link",
        blank: false,
        internalLink: { pageType: "path", slug: slug, language },
      }}
      variant="h4"
    />
  );
}

export function getDescriptionForArrayFilter<T>(
  selectedItems: T[],
  descriptionSelector: (item: T) => string | undefined,
) {
  if (selectedItems.length === 0) {
    return "";
  }

  const description = descriptionSelector(selectedItems[0]) ?? "";

  return selectedItems.length > 1
    ? `${description} + ${selectedItems.length - 1}`
    : description;
}

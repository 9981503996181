import { Slider, SliderRange, SliderThumb, SliderTrack } from "./slider";

type RangeSliderProps = {
  defaultValue?: [number, number];
  min: number;
  max: number;
  step?: number;
  value: [number, number];
  onValueChange: (value: [number, number]) => void;
};

export const RangeSlider = ({
  min,
  max,
  defaultValue = [min, max],
  step = 0.1,
  value,
  onValueChange,
}: RangeSliderProps) => {
  return (
    <Slider
      defaultValue={defaultValue}
      min={min}
      max={max}
      step={step}
      value={value}
      onValueChange={onValueChange}
    >
      <SliderTrack>
        <SliderRange />
      </SliderTrack>
      <SliderThumb />
      <SliderThumb />
    </Slider>
  );
};

import { Button } from "@components/ui/button";
import { useTranslations } from "next-intl";
import { PropsWithChildren } from "react";

type SelectFilterFooterProps = {
  onClear: () => void;
  resultCount: number;
  CloseComponent: React.FunctionComponent<PropsWithChildren<{}>>;
};

export function SelectFilterFooter({
  onClear,
  resultCount,
  CloseComponent,
}: SelectFilterFooterProps) {
  const t = useTranslations("Filters.Paths");

  return (
    <div className="flex items-center justify-between border-t border-t-grey p-lg">
      <CloseComponent>
        <Button onClick={onClear} variant="ghost" className="p-none">
          {t("Reset")}
        </Button>
      </CloseComponent>
      <CloseComponent>
        <Button>
          {t("Visit")} {resultCount} {t("Results")}
        </Button>
      </CloseComponent>
    </div>
  );
}

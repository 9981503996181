import { ImageBlockType } from "@components/blocks/imageBlock/ImageBlock";
import { PathSanityResponse } from "@repo/shared-types";
import Image from "next/image";
import { useTranslations } from "next-intl";

import { PathCard } from "../pathCard/PathCard";

interface RegionProps {
  name: string;
  icon: { url: string; height: number; width: number };
  language: string;
  paths: {
    id: string;
    slug: string;
    name: string;
    region: string;
    length: number;
    difficulty: PathSanityResponse["difficulty"];
    estimatedTime: string;
    image: ImageBlockType;
  }[];
}

export async function Region({ name, icon, paths, language }: RegionProps) {
  const t = useTranslations("Filters.Paths");
  return (
    <li className="px-md lg:p-4xl">
      <div className="relative flex items-end justify-between py-lg lg:gap-3xl lg:pb-2xl lg:pt-0">
        <div className="flex flex-col gap-sm text-primary-dark lg:gap-md">
          <h3 className="text-4xl font-bold lg:text-5xl">
            {name} ({paths.length} {t("Stages")})
          </h3>
        </div>
        <span className="max-lg:hidden">
          <Image
            src={icon.url}
            alt={name}
            height={icon.height}
            width={icon.width}
          />
        </span>
      </div>
      <ul className="grid grid-cols-1 gap-2xl sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 lg:gap-x-3xl lg:gap-y-6xl">
        {paths.map(path => (
          <li key={path.id} className="[&_a]:-mt-4">
            <PathCard
              {...path}
              language={language}
              type={undefined}
              region={name}
            />
          </li>
        ))}
      </ul>
    </li>
  );
}

"use client";

import { ImageBlockType } from "@components/blocks/imageBlock/ImageBlock";
import { TabContent, TabsContainer } from "@components/ui/tab";
import { RoutesOverviewFiltersResponse } from "@lib/utils/types/filters/RoutesOverviewFiltersResponse";
import { PathSanityResponse, RouteType } from "@repo/shared-types";

import { useFilteredRegions } from "../hooks/useFilteredRegions";
import { useRouteOverviewActiveFilters } from "../hooks/useRoutesOverviewActiveFilters";
import { SelectFilters } from "./selectFilters/SelectFilters";
import { TypeFilters } from "./TypeFilters";

type RoutesOverviewFormProps = {
  regions: {
    id: string;
    name: string;
    icon: { url: string; height: number; width: number };
    paths: {
      id: string;
      slug: string;
      name: string;
      region: string;
      type: RouteType;
      length: number;
      difficulty: PathSanityResponse["difficulty"];
      estimatedTime: string;
      image: ImageBlockType;
    }[];
  }[];
  allFilters: RoutesOverviewFiltersResponse;
};

export function RoutesOverviewForm({
  regions,
  allFilters,
}: RoutesOverviewFormProps) {
  const activeFilters = useRouteOverviewActiveFilters(allFilters);
  const filteredRegions = useFilteredRegions(regions, activeFilters.values);
  const resultCount = filteredRegions.reduce(
    (sum, x) => sum + x.paths.length,
    0
  );

  return (
    <TabsContainer
      defaultValue={activeFilters.values.type}
      value={activeFilters.values.type}
      onValueChange={(value: string) => {
        if (activeFilters.values.type !== value) {
          activeFilters.set({ type: value !== allFilters[0]?.id ? value : [] });
        }
      }}
    >
      <TypeFilters allFilters={allFilters} regions={regions} />
      {allFilters.map(filters => (
        <TabContent key={filters.id} value={filters.id}>
          <SelectFilters
            filters={filters}
            resultCount={resultCount}
            activeFilters={activeFilters}
          />
        </TabContent>
      ))}
    </TabsContainer>
  );
}

import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerTrigger,
} from "@components/ui/drawer";
import {
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger,
} from "@components/ui/popover";
import { PropsWithChildren, useState } from "react";

import { SelectFilterFooter } from "./SelectFilterFooter";
import { SelectFilterTrigger } from "./SelectFilterTrigger";

type SelectFilterProps = PropsWithChildren<{
  title: string;
  alternativeTitle: string;
  onClear: () => void;
  resultCount: number;
  hasChanges: boolean;
}>;

export function SelectFilter({
  children,
  title,
  alternativeTitle,
  onClear,
  resultCount,
  hasChanges,
}: SelectFilterProps) {
  const [open, setOpen] = useState(false);

  return (
    <div className="flex flex-1">
      <div className="flex flex-1 sm:hidden">
        <Drawer>
          <SelectFilterTrigger
            title={hasChanges ? alternativeTitle ?? title : title}
            state={open ? "open" : hasChanges ? "changed" : "default"}
            onClear={onClear}
            TriggerComponent={DrawerTrigger}
          />
          <DrawerContent className="rounded-t-2xl">
            {children}
            <DrawerFooter className="p-0">
              <SelectFilterFooter
                onClear={onClear}
                resultCount={resultCount}
                CloseComponent={props => <DrawerClose {...props} asChild />}
              />
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </div>
      <div className="flex flex-1 max-sm:hidden">
        <Popover open={open} onOpenChange={setOpen}>
          <SelectFilterTrigger
            title={hasChanges ? alternativeTitle ?? title : title}
            state={open ? "open" : hasChanges ? "changed" : "default"}
            onClear={onClear}
            TriggerComponent={PopoverTrigger}
          />
          <PopoverPortal>
            <PopoverContent
              align="start"
              className="z-10 lg:w-[var(--radix-popover-trigger-width)]"
            >
              {children}
              <SelectFilterFooter
                onClear={onClear}
                resultCount={resultCount}
                CloseComponent={props => <PopoverClose {...props} asChild />}
              />
            </PopoverContent>
          </PopoverPortal>
        </Popover>
      </div>
    </div>
  );
}

import { PropsWithChildren } from "react";

type SelectFilterContentProps = PropsWithChildren<{
  title: string;
  subtitle?: string;
}>;

export function SelectFilterContent({
  title,
  subtitle,
  children,
}: SelectFilterContentProps) {
  return (
    <div className="flex flex-col gap-xl p-lg pt-xl">
      <div className="flex items-center justify-between px-md text-primary-dark">
        <span className="text-xl font-bold">{title}</span>
        <span className="text-sm">{subtitle}</span>
      </div>
      {children}
    </div>
  );
}

"use client";
import { ITabBlock } from "@components/blocks/tabBlock/TabBlock";
import { createContext, ReactNode, useContext, useRef, useState } from "react";

export interface ITabsContext {
  selectedTab: string;
  changeTab: (value: string) => void;
  tabs: ITabBlock[];
  initialLoad: boolean;
}

export const tabsContext = createContext<ITabsContext | undefined>(undefined);

export interface ITabsProvider {
  initialTab: string;
  tabs: ITabBlock[];
  children: ReactNode;
}

const TabsContextProvider = tabsContext.Provider;

export const TabsProvider = ({ initialTab, tabs, children }: ITabsProvider) => {
  const [selectedTab, setSelectedTab] = useState(initialTab);
  const initialLoadRef = useRef(true);

  const changeTab = (value: string) => {
    initialLoadRef.current = false;
    setSelectedTab(value);
  };

  return (
    <TabsContextProvider
      value={{
        selectedTab: selectedTab,
        changeTab: changeTab,
        tabs: tabs,
        initialLoad: initialLoadRef.current,
      }}
    >
      {children}
    </TabsContextProvider>
  );
};

export const useTabs = () => {
  const context = useContext(tabsContext);

  if (!context) {
    throw new Error("useTabs cannot be used outside of TabsProvider");
  }

  return context;
};

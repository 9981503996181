import { RoutesOverviewFiltersResponse } from "@lib/utils/types/filters/RoutesOverviewFiltersResponse";
import { PathSanityResponse, RouteType } from "@repo/shared-types";

import { Query, useRouteOverviewQuery } from "./useRouteOverviewQuery";

export type RoutesOverviewActiveFilters = ReturnType<
  typeof useRouteOverviewActiveFilters
>;

export const useRouteOverviewActiveFilters = (
  filters: RoutesOverviewFiltersResponse
) => {
  const { query, ...actions } = useRouteOverviewQuery([
    "type",
    "lengthMin",
    "lengthMax",
    "pathId",
    "difficulty",
  ]);

  const type = readType(filters, query);
  const length = readLength(filters, type, query);

  return {
    ...actions,
    values: {
      type,
      difficulties: query.difficulty as PathSanityResponse["difficulty"][],
      pathIds: query.pathId,
      length,
    },
  };
};

function readType(
  filters: RoutesOverviewFiltersResponse,
  query: Query<string[]>
) {
  const initialType = query.type[0];
  const defaultType = filters[0]?.id ?? "hiking";
  const type =
    initialType === "hiking" || initialType === "biking"
      ? initialType
      : defaultType;

  return type;
}

function readLength(
  filters: RoutesOverviewFiltersResponse,
  currentRouteType: RouteType,
  query: Query<string[]>
) {
  const min = Number.parseFloat(query.lengthMin[0] ?? "");
  const max = Number.parseFloat(query.lengthMax[0] ?? "");
  const defaultLengthForType = filters.find(x => x.id === currentRouteType)
    ?.length ?? {
    min: 0,
    max: 100,
  };
  const initialLength = { min, max };
  const length =
    !Number.isNaN(min) && !Number.isNaN(max)
      ? initialLength
      : defaultLengthForType;

  return length;
}

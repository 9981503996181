import { imageBuilder } from "@lib/utils/sanity/imgBuilder";
import { Sizes } from "@lib/utils/types/Sizes";
import { SanityImageSource } from "@sanity/image-url/lib/types/types";
import Image from "next/image";

export type ImageBlockType = SanityImageSource & {
  lqip: string;
  alternativeText: string;
};

interface IImageBlock {
  imageBlock?: ImageBlockType | null;
  sizes: Sizes;
  objectFit?: React.CSSProperties["objectFit"];
  className?: string;
}

export function ImageBlock({
  imageBlock,
  sizes,
  objectFit = "cover",
  className,
}: IImageBlock) {
  const src = imageBlock
    ? imageBuilder.image(imageBlock).auto("format").format("webp").url()
    : "/assets/placeholder-image.png";

  return (
    <Image
      className={className}
      src={src}
      placeholder={imageBlock?.lqip ? "blur" : undefined}
      blurDataURL={imageBlock?.lqip}
      fill={true}
      style={{
        width: "100%",
        height: "100%",
        objectFit,
        objectPosition: "center",
      }}
      sizes={sizes.join(", ")}
      alt={imageBlock?.alternativeText ?? ""}
    />
  );
}

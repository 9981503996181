import { cn } from "@lib/utils";
import * as SliderPrimitive from "@radix-ui/react-slider";
import React from "react";

const Slider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>
>(({ className, ...props }, ref) => (
  <SliderPrimitive.Root
    ref={ref}
    className={cn("h-6] flex items-center relative", className)}
    {...props}
  />
));
Slider.displayName = SliderPrimitive.Root.displayName;

const SliderThumb = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Thumb>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Thumb>
>(({ className, ...props }, ref) => (
  <SliderPrimitive.Thumb
    ref={ref}
    className={cn(
      "h-6 w-6 bg-white block rounded-full shadow-[0px_0px_6px_0px_#0000004D]",
      className,
    )}
    {...props}
  />
));
SliderThumb.displayName = SliderPrimitive.Thumb.displayName;

const SliderRange = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Range>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Range>
>(({ className, ...props }, ref) => (
  <SliderPrimitive.Range
    ref={ref}
    className={cn("h-[3px] bg-primary absolute", className)}
    {...props}
  />
));

SliderRange.displayName = SliderPrimitive.Range.displayName;

const SliderTrack = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Track>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Track>
>(({ className, ...props }, ref) => (
  <SliderPrimitive.Track
    ref={ref}
    className={cn("h-[3px] bg-[#AAAAAA] rounded-full flex-1", className)}
    {...props}
  />
));

SliderTrack.displayName = SliderPrimitive.Track.displayName;

export { Slider, SliderRange, SliderThumb, SliderTrack };

"use client";

import { Icon } from "@components/blocks/icon/Icon";
import { ImageBlockType } from "@components/blocks/imageBlock/ImageBlock";
import { Region } from "@components/blocks/region/Region";
import { Button } from "@components/ui/button";
import { RoutesOverviewFiltersResponse } from "@lib/utils/types/filters/RoutesOverviewFiltersResponse";
import { PathSanityResponse, RouteType } from "@repo/shared-types";
import { useTranslations } from "next-intl";

import { useFilteredRegions } from "../hooks/useFilteredRegions";
import { useRouteOverviewActiveFilters } from "../hooks/useRoutesOverviewActiveFilters";

export type RoutesOverviewSearchResultProps = {
  regions: {
    id: string;
    name: string;
    icon: { url: string; height: number; width: number };
    paths: {
      id: string;
      slug: string;
      name: string;
      region: string;
      type: RouteType;
      length: number;
      difficulty: PathSanityResponse["difficulty"];
      estimatedTime: string;
      image: ImageBlockType;
    }[];
  }[];
  allFilters: RoutesOverviewFiltersResponse;
  language: string;
};

export const RoutesOverviewSearchResult = ({
  regions,
  allFilters,
  language,
}: RoutesOverviewSearchResultProps) => {
  const activeFilters = useRouteOverviewActiveFilters(allFilters);
  const filteredRegions = useFilteredRegions(regions, activeFilters.values);
  const t = useTranslations("Filters.Paths");

  if (filteredRegions.length === 0) {
    return (
      <div className="flex flex-col items-center bg-[#F7FAF3] px-md py-4xl lg:px-0 lg:py-6xl">
        <div className="flex w-[272px] flex-col items-center gap-xl lg:gap-3xl">
          <Icon iconName="search" />
          <span className="text-center text-xl">{t("EmptyState")}</span>
          <Button
            onClick={() =>
              activeFilters.set({ type: activeFilters.values.type })
            }
          >
            {t("Reset")}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <ul className="flex flex-col bg-[#F7FAF3] pb-2xl pt-none ">
      {filteredRegions.map(region => (
        <Region language={language} key={region.id} {...region} />
      ))}
    </ul>
  );
};

import { processParams } from "@repo/helpers";
import { SearchParams } from "@repo/shared-types";
import { usePathname, useRouter, useSearchParams } from "next/navigation";

export type Query<T extends readonly string[]> = {
  [Key in T[number]]: string[];
};

// It may be a good idea to create more abstract solution and merge it with useMapForm
export function useRouteOverviewQuery(allowedQueryKeys: string[]) {
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const router = useRouter();

  const query: Query<string[]> = {};

  allowedQueryKeys.forEach(key => {
    query[key] = searchParams.getAll(key);
  });

  function updateQuery(query: SearchParams) {
    const params = processParams(query);

    router.push(`${pathname}?${params.toString()}`, { scroll: false });
  }

  function toggle(name: string, value: string) {
    toggleAll(name, [value]);
  }

  function toggleAll(name: string, values: string[]) {
    function getNewValues() {
      const allCheckedValues = searchParams.getAll(name);
      const incomingCheckedValues = values.filter(x =>
        allCheckedValues.includes(x)
      );
      const valuesToUpdate = allCheckedValues.filter(x => !values.includes(x));
      const shouldCheckValues = incomingCheckedValues.length !== values.length;

      return shouldCheckValues ? valuesToUpdate.concat(values) : valuesToUpdate;
    }

    updateQuery({
      ...query,
      [name]: getNewValues(),
    });
  }

  function set(query: SearchParams) {
    updateQuery(query);
  }

  function replace(newQuery: SearchParams) {
    updateQuery({
      ...query,
      ...newQuery,
    });
  }

  function clear(name: string) {
    updateQuery({
      ...query,
      [name]: [],
    });
  }

  return {
    query,
    toggle,
    toggleAll,
    clear,
    set,
    replace,
  } as const;
}

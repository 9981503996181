"use client";
import { PathTypeBadge } from "@components/pathTypeBadge/PathTypeBadge";
import { AspectRatio } from "@components/ui/aspect-ratio";
import { composeHref } from "@lib/utils/composeHref";
import { useRouter } from "next/navigation";
import React from "react";

import { ImageBlock, ImageBlockType } from "../imageBlock/ImageBlock";
import { ILink, LinkBlock } from "../linkBlock/LinkBlock";

export interface INavigationItem {
  _type: "navigationItem";
  _key: string;
  label: string;
  title: string;
  link: ILink;
  imageBlock: ImageBlockType;
  type?: "hiking" | "biking";
}

export const NavigationItem = ({
  type,
  label,
  title,
  link,
  imageBlock,
}: INavigationItem) => {
  const router = useRouter();

  return (
    <article
      className="min-h-[400px] cursor-pointer overflow-hidden rounded-xl"
      onClick={() => {
        router.push(composeHref(link));
      }}
    >
      <AspectRatio className="min-h-[400px]" ratio={1}>
        <div className="relative h-full w-full">
          {type && (
            <PathTypeBadge
              className="right-6 top-6 lg:right-4 lg:top-4"
              type={type}
            />
          )}
          <ImageBlock
            objectFit="cover"
            imageBlock={imageBlock}
            sizes={[
              "(min-width: 768px) 50vw",
              "(min-width: 1400px) 656px",
              "100vw",
            ]}
          />
          <div className="absolute inset-0 bg-black/20" />
        </div>
        <div className="absolute inset-0 flex flex-col justify-end">
          <div className="pb-6 pl-6 pr-8 text-primary-light lg:px-10 lg:pb-10">
            <span className="mb-1 block text-sm uppercase leading-none tracking-tight">
              {label}
            </span>
            <h3 className="line-clamp-4 text-ellipsis text-3xl font-bold lg:text-4xl">
              {title}
            </h3>
            {link.linkType && (
              <LinkBlock
                link={link}
                variant="Outline"
                className="mt-8 h-auto border-accent-foreground px-8 py-3 text-lg text-primary-light hover:bg-accent-foreground/30"
              />
            )}
          </div>
        </div>
      </AspectRatio>
    </article>
  );
};

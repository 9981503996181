import { Accordion } from "@components/ui/accordion";
import { RoutesOverviewRegionFilterResponse } from "@lib/utils/types/filters/RoutesOverviewFiltersResponse";
import { useTranslations } from "next-intl";

import { SelectFilter } from "../selectFilter/SelectFilter";
import { SelectFilterContent } from "../selectFilter/SelectFilterContent";
import { SelectFilterItem } from "../selectFilter/SelectFilterItem";
import { getDescriptionForArrayFilter } from "./getDescriptionFromArrayFilter";

type PathsFilterProps = {
  availableRegions: RoutesOverviewRegionFilterResponse[];
  resultCount: number;
  selectedPaths: string[];
  onToggle: (pathId: string) => void;
  onToggleAll: (pathIds: string[]) => void;
  onClear: () => void;
};

export function PathsFilter({
  availableRegions,
  resultCount,
  selectedPaths,
  onToggle,
  onToggleAll,
  onClear,
}: PathsFilterProps) {
  const t = useTranslations("Filters.Paths");

  const availablePaths = availableRegions.flatMap(x => x.paths);
  const hasChanges = selectedPaths.length > 0;

  const getCheckedState = (pathIds: string[]) => {
    const selectedChildren = pathIds.filter(x => selectedPaths.includes(x));

    if (selectedChildren.length === pathIds.length) {
      return true;
    } else if (selectedChildren.length === 0) {
      return false;
    } else {
      return "indeterminate";
    }
  };

  return (
    <SelectFilter
      title={t("AllRegions")}
      alternativeTitle={getDescriptionForArrayFilter(
        selectedPaths,
        pathId => availablePaths.find(x => x.id === pathId)?.name
      )}
      onClear={onClear}
      resultCount={resultCount}
      hasChanges={hasChanges}
    >
      <SelectFilterContent title={t("Regions")}>
        <Accordion type="single" collapsible>
          {availableRegions.map(region => (
            <SelectFilterItem
              id={region.id}
              key={region.id}
              label={region.name}
              checked={getCheckedState(region.paths.map(x => x.id))}
              onToggle={() => onToggleAll(region.paths.map(x => x.id))}
              items={region.paths.map(path => ({
                id: path.id,
                label: path.name,
                checked: selectedPaths.includes(path.id),
                onToggle: () => onToggle(path.id),
              }))}
            />
          ))}
        </Accordion>
      </SelectFilterContent>
    </SelectFilter>
  );
}

"use client";

import { cn } from "@lib/utils";
import * as Tabs from "@radix-ui/react-tabs";
import React from "react";

const TabsContainer = Tabs.Root;
const TabContent = Tabs.Content;

const TabHeaders = React.forwardRef<
  React.ElementRef<typeof Tabs.List>,
  React.ComponentPropsWithoutRef<typeof Tabs.List>
>(({ className, ...props }, ref) => (
  <Tabs.List
    ref={ref}
    className={cn("flex items-center sm:gap-2xl ", className)}
    {...props}
  />
));

TabHeaders.displayName = Tabs.List.displayName;

const TabHeader = React.forwardRef<
  React.ElementRef<typeof Tabs.Trigger>,
  React.ComponentPropsWithoutRef<typeof Tabs.Trigger>
>(({ className, ...props }, ref) => (
  <Tabs.Trigger
    ref={ref}
    className={cn(
      "max-sm:flex-1 p-xs lg:px-0 font-bold text-sm lg:text-base border-b-2 border-b-transparent [&[data-state='active']]:border-b-primary-dark",
      className,
    )}
    {...props}
  />
));

TabHeader.displayName = Tabs.Trigger.displayName;

export { TabContent, TabHeader, TabHeaders, TabsContainer };

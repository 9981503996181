"use client";
import { PathTypeBadge } from "@components/pathTypeBadge/PathTypeBadge";
import { AspectRatio } from "@components/ui/aspect-ratio";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardSubtitle,
  CardTitle,
} from "@components/ui/card";
import { composeHref } from "@lib/utils/composeHref";
import { cva } from "class-variance-authority";
import { useRouter } from "next/navigation";
import { ReactNode } from "react";

import { ImageBlock, ImageBlockType } from "../imageBlock/ImageBlock";
import { ILink, LinkBlock } from "../linkBlock/LinkBlock";
export interface IContentCard {
  label: ReactNode;
  title: string;
  content: string;
  additionalInfo: string | ReactNode;
  imageBlock?: ImageBlockType | null;
  imageRatio?: number;
  type?: "hiking" | "biking";
  link: ILink;
  variant: "h2" | "h3" | "h4" | "h5" | "h6";
  cardSubtitleStyle?: string;
}

export function ContentCard({
  title,
  label,
  content,
  additionalInfo,
  imageBlock,
  type,
  link,
  variant,
  imageRatio,
  cardSubtitleStyle,
}: IContentCard) {
  const router = useRouter();

  return (
    <Card
      role="button"
      className="h-[100%] cursor-pointer border-none bg-transparent shadow-none"
      onClick={() => {
        router.push(composeHref(link));
      }}
    >
      <CardHeader className="mb-6 space-y-0 rounded-xl p-0 sm:w-[100%] md:w-[100%]">
        <AspectRatio
          className="relative overflow-hidden rounded-xl"
          ratio={imageRatio ?? 1}
        >
          {type && <PathTypeBadge type={type} />}
          <ImageBlock
            className="transition duration-500 hover:scale-125"
            objectFit="cover"
            imageBlock={imageBlock}
            sizes={["(min-width: 1440px) 720px", "100vw"]}
          />
        </AspectRatio>
      </CardHeader>
      <CardTitle className="mb-2" variant={variant}>
        {label}
      </CardTitle>
      <CardSubtitle
        variant={variant}
        className={cardSubtitleStyle || "text-2xl md:text-4xl"}
      >
        {title}
      </CardSubtitle>
      <CardContent>{content}</CardContent>
      <CardDescription>{additionalInfo}</CardDescription>
      <CardFooter>
        <LinkBlock link={{ ...link }} />
      </CardFooter>
    </Card>
  );
}

import { PathSanityResponse, RouteType } from "@repo/shared-types";

type Region = {
  id: string;
  paths: {
    id: string;
    length: number;
    type: RouteType;
    difficulty: PathSanityResponse["difficulty"];
  }[];
};

type RoutesOverviewSectionActiveFilters = {
  length: { min: number; max: number };
  difficulties: PathSanityResponse["difficulty"][];
  pathIds: string[];
  type: RouteType;
};

export const useFilteredRegions = <TRegion extends Region>(
  regions: TRegion[],
  filters: RoutesOverviewSectionActiveFilters
) => {
  return filterRegions(regions, filters);
};

const filterRegions = <TRegion extends Region>(
  regions: TRegion[],
  filters: RoutesOverviewSectionActiveFilters
) => {
  const filteredRegions = regions
    .map(region => ({ ...region, paths: filterPaths(region.paths, filters) }))
    .filter(x => x.paths.length > 0);

  return filteredRegions;
};

const filterPaths = (
  paths: Region["paths"],
  filters: Parameters<typeof isPathValid>[1]
) => {
  const filteredPaths = paths.filter(path => isPathValid(path, filters));

  return filteredPaths;
};

const isPathValid = (
  path: Region["paths"][0],
  {
    length,
    difficulties,
    pathIds,
    type,
  }: Omit<RoutesOverviewSectionActiveFilters, "regionIds">
) => {
  const isPathChecked = pathIds.length === 0 || pathIds.includes(path.id);
  const isTypeCorrect = path.type === type;
  const isLengthInRange =
    path.length >= length.min && path.length <= length.max;
  const isDifficultyChecked =
    difficulties.length === 0 || difficulties.includes(path.difficulty);

  return (
    isPathChecked && isDifficultyChecked && isLengthInRange && isTypeCorrect
  );
};

import { RangeSlider } from "@components/ui/range-slider";
import { useUpdateEffect } from "@lib/useUpdateEffect";
import { useTranslations } from "next-intl";
import { useState } from "react";

import { SelectFilter } from "../selectFilter/SelectFilter";
import { SelectFilterContent } from "../selectFilter/SelectFilterContent";

type Range = { min: number; max: number };

type LengthFilterProps = {
  resultCount: number;
  rangeBounds: Range;
  value: Range;
  onValueChanged: (value: Range) => void;
};

export function LengthFilter({
  resultCount,
  rangeBounds,
  value,
  onValueChanged,
}: LengthFilterProps) {
  const t = useTranslations("Filters.Paths");

  const [range, setRange] = useState(value);
  const selectedDistance = `${range.min} ${t("Km")} - ${range.max} ${t("Km")}`;
  const wholeDistance = `${rangeBounds.min} ${t("Km")} - ${rangeBounds.max} ${t("Km")}`;
  const hasChanges =
    rangeBounds.min !== range.min || rangeBounds.max !== range.max;

  useUpdateEffect(() => {
    onValueChanged(range);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range]);

  return (
    <SelectFilter
      title={t("Length")}
      alternativeTitle={selectedDistance}
      onClear={() => setRange(rangeBounds)}
      resultCount={resultCount}
      hasChanges={hasChanges}
    >
      <SelectFilterContent title={t("Length")} subtitle={wholeDistance}>
        <div className="p-md pt-0">
          <RangeSlider
            defaultValue={[rangeBounds.min, rangeBounds.max]}
            value={[range.min, range.max]}
            onValueChange={([min, max]) => setRange({ min, max })}
            min={rangeBounds.min}
            max={rangeBounds.max}
          />
        </div>
      </SelectFilterContent>
    </SelectFilter>
  );
}

import { SearchParams } from "@repo/shared-types";

export function baseURL() {
  if (process.env.NODE_ENV === "development") {
    return "http://localhost:3000";
  }
  return typeof window === "object"
    ? window.location.origin
    : `https://${process.env.VERCEL_URL}`;
}

export function processParams(obj: SearchParams) {
  let params = new URLSearchParams();

  for (let key in obj) {
    const value = obj[key];

    if (value === null || value === undefined || value === "") {
      continue;
    }

    if (Array.isArray(value)) {
      for (let index in value) {
        params.append(key, value[index].toString());
      }
    } else {
      params.append(key, value.toString());
    }
  }
  return params;
}

import { cn } from "@lib/utils";
import { ChevronDown, X } from "lucide-react";
import { PropsWithChildren } from "react";

type SelectFilterTriggerProps = {
  title: string;
  onClear: () => void;
  state: "default" | "open" | "changed";
  TriggerComponent: React.FunctionComponent<
    PropsWithChildren<{ className?: string }>
  >;
};

export function SelectFilterTrigger({
  title,
  state,
  onClear,
  TriggerComponent,
}: SelectFilterTriggerProps) {
  const Icon = state === "changed" ? X : ChevronDown;

  return (
    <TriggerComponent className="flex-1 [&[data-state=open]>div>svg]:rotate-180">
      <div
        className={cn(
          `flex gap-lg justify-between items-center bg-grey-light text-primary-dark 
        rounded-xl px-md py-sm  transition-colors duration-300`,
          { "bg-black": state === "changed" },
          { "bg-accent": state === "open" },
          { "text-white": state !== "default" },
        )}
      >
        <p className="text-base whitespace-nowrap">{title}</p>
        {
          <Icon
            className="h-6 w-6 shrink-0 transition-transform duration-300"
            onClick={(e) => {
              if (state === "changed") {
                e.preventDefault();
                onClear();
              }
            }}
          />
        }
      </div>
    </TriggerComponent>
  );
}

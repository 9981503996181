import { TabHeader, TabHeaders } from "@components/ui/tab";
import { RoutesOverviewFiltersResponse } from "@lib/utils/types/filters/RoutesOverviewFiltersResponse";
import { RouteType } from "@repo/shared-types";
import { cva } from "class-variance-authority";
import { useTranslations } from "next-intl";

type TypeFiltersProps = {
  regions: {
    id: string;
    paths: {
      type: RouteType;
    }[];
  }[];
  allFilters: RoutesOverviewFiltersResponse;
};

export function TypeFilters({ allFilters, regions }: TypeFiltersProps) {
  const t = useTranslations("Filters.Paths");
  const tabVariants = cva("h-2 w-2 rounded-full hidden sm:block", {
    variants: {
      type: {
        biking: "bg-[#FA6732]",
        hiking: "bg-[#F9C026]",
      },
    },
  });

  return (
    <TabHeaders className="px-md pt-lg lg:px-4xl">
      {allFilters.map(filter => {
        const pathsInRegion = regions.flatMap(region =>
          region.paths.filter(path => path.type === filter.id)
        ).length;

        return (
          <TabHeader
            key={filter.id}
            value={filter.id}
            className="sm:flex sm:items-center sm:gap-md"
          >
            <span className={tabVariants({ type: filter.id })} />
            <span>
              {filter.name} ({pathsInRegion} {t("Total")})
            </span>
          </TabHeader>
        );
      })}
    </TabHeaders>
  );
}

import { RoutesOverviewDifficultyFilterResponse } from "@lib/utils/types/filters/RoutesOverviewFiltersResponse";
import { useTranslations } from "next-intl";

import { SelectFilter } from "../selectFilter/SelectFilter";
import { SelectFilterContent } from "../selectFilter/SelectFilterContent";
import { SelectFilterItem } from "../selectFilter/SelectFilterItem";
import { getDescriptionForArrayFilter } from "./getDescriptionFromArrayFilter";

type DifficultiesFilterProps = {
  availableDifficulties: RoutesOverviewDifficultyFilterResponse[];
  resultCount: number;
  selectedDifficulties: string[];
  onToggle: (pathId: string) => void;
  onClear: () => void;
};

export function DifficultiesFilter({
  availableDifficulties,
  resultCount,
  selectedDifficulties,
  onToggle,
  onClear,
}: DifficultiesFilterProps) {
  const t = useTranslations("Filters.Paths");

  return (
    <SelectFilter
      title={t("AllDifficulties")}
      alternativeTitle={getDescriptionForArrayFilter(
        selectedDifficulties,
        id => availableDifficulties.find(x => x.id === id)?.name
      )}
      resultCount={resultCount}
      onClear={onClear}
      hasChanges={selectedDifficulties.length > 0}
    >
      <SelectFilterContent title={t("Difficulties")}>
        <div>
          {availableDifficulties.map(x => (
            <SelectFilterItem
              id={x.id}
              key={x.id}
              label={x.name}
              checked={selectedDifficulties.includes(x.id)}
              onToggle={() => onToggle(x.id)}
            />
          ))}
        </div>
      </SelectFilterContent>
    </SelectFilter>
  );
}

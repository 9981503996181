"use client";
import {
  TabBlockContent,
  TabBlockHeader,
} from "@components/blocks/tabBlock/TabBlock";
import { useTabs } from "@components/tabs/TabsProvider";
import { TabHeaders, TabsContainer } from "@components/ui/tab";
import { useCallback, useEffect, useRef, useState } from "react";

export function TabsSection() {
  const [indicatorPos, setIndicatorPos] = useState({
    width: 0,
    offsetX: 0,
  });
  const { tabs, changeTab, selectedTab, initialLoad } = useTabs();

  const tabsRef = useRef<Record<string, HTMLElement | null>>({});

  const handleTabChange = (value: string) => {
    changeTab(value);
    updateIndicatorPos(value);
  };

  const updateIndicatorPos = useCallback(
    (value = selectedTab) => {
      const newTab = tabsRef.current[value];

      setIndicatorPos({
        width: newTab?.clientWidth ?? 0,
        offsetX: newTab?.offsetLeft ?? 0,
      });
    },
    [selectedTab]
  );

  useEffect(() => {
    const callback = () => updateIndicatorPos();

    window.addEventListener("resize", callback);

    return () => window.removeEventListener("resize", callback);
  }, [updateIndicatorPos]);

  return (
    <section className="my-10 max-w-[847px] py-6 md:container">
      <TabsContainer value={selectedTab} onValueChange={handleTabChange}>
        <TabHeaders className="relative my-4 w-full overflow-x-auto sm:gap-[0px]">
          {tabs.map(tab => (
            <TabBlockHeader
              key={tab._key}
              {...tab}
              isActive={initialLoad}
              ref={el => {
                tabsRef.current[tab._key] = el;
              }}
            >
              {tab.title}
            </TabBlockHeader>
          ))}
          <TabIndicator {...indicatorPos} />
        </TabHeaders>

        <div className="mb-10 mt-16 auto-rows-[0fr] grid-rows-[1fr] overflow-hidden px-4 transition-all delay-1000 duration-0 md:mb-6 md:px-0">
          {tabs.map(tab => (
            <TabBlockContent key={tab._key} {...tab} />
          ))}
        </div>
      </TabsContainer>
    </section>
  );
}

interface ITabIndicator {
  offsetX: number;
  width: number;
}

const TabIndicator = ({ offsetX, width }: ITabIndicator) => (
  <div
    className="absolute bottom-0 h-0.5 bg-border transition-all duration-300"
    aria-hidden="true"
    style={{ left: offsetX, width: width }}
  />
);

import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@components/ui/accordion";
import { Checkbox } from "@components/ui/checkbox";
import { Label } from "@components/ui/label";
import {
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger,
} from "@components/ui/popover";
import { cn } from "@lib/utils";
import { Info } from "lucide-react";

type SelectFilterItemProps = {
  id: string;
  label: string;
  checked: boolean | "indeterminate";
  onToggle: () => void;
  items?: Omit<SelectFilterItemProps, "items">[];
  isNested?: boolean;
  tooltip?: string;
};

export function SelectFilterItem({
  id,
  label,
  onToggle,
  checked,
  items = [],
  isNested = false,
  tooltip,
}: SelectFilterItemProps) {
  const labelComponent =
    items.length > 0 ? (
      <AccordionTrigger className="p-0">
        <Label>{label}</Label>
      </AccordionTrigger>
    ) : (
      <Label htmlFor={id}>{label}</Label>
    );

  const itemComponent = (
    <div
      className={cn("flex items-center gap-xl p-md hover:bg-grey-light", {
        ["[&:first-child]:border-t border-t-grey"]: isNested,
        ["[&:last-child]:border-b border-b-grey"]: isNested,
      })}
    >
      <Checkbox
        id={id}
        className="rounded-[4px] border-accent"
        checked={checked}
        onCheckedChange={onToggle}
      />
      <div className="flex flex-1 items-center gap-xs">
        {labelComponent}
        {tooltip && (
          <Popover>
            <PopoverTrigger>
              <Info className="h-4 w-4" />
            </PopoverTrigger>
            <PopoverPortal>
              <PopoverContent className="p-4">
                <span className="text-base text-primary-dark">{tooltip}</span>
              </PopoverContent>
            </PopoverPortal>
          </Popover>
        )}
      </div>
    </div>
  );

  if (items.length === 0) {
    return itemComponent;
  }

  return (
    <AccordionItem value={label} className="border-none">
      {itemComponent}
      <AccordionContent className="p-0">
        {items.map((item) => (
          <SelectFilterItem key={item.label} {...item} isNested />
        ))}
      </AccordionContent>
    </AccordionItem>
  );
}
